<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Menu - Zápatí - seznam</strong>
      </div>
      <action-tools :router-options="routerOptions" :handle-refresh="handleRefresh"></action-tools>
    </div>
    <div class="card-body">
      <div class="table-operations">
        <language-tab></language-tab>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <a-dropdown :placement="'bottomLeft'">
            <a-button>Hromadné akce
              <a-icon type="down"/>
            </a-button>
            <a-menu slot="overlay">
              <a-menu-item :disabled="selectedRowKeys.length === 0" :class="{'disabled': selectedRowKeys.length === 0}">
                <a-popconfirm @confirm="confirmDelete(selectedRowKeys)" okText="Potvrdit" cancelText="Zrušit">
                  <template slot="title">
                    <p>Opravdu si přejete odstranit tyto položky?</p>
                    <div v-if="selectedRowKeys.length > 0">
                      {{ selectedRowKeys }}
                    </div>
                  </template>
                  Odstranit
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
        <div class="text-right col-md-6">
          <a-input-search
            placeholder="Hledat..."
            style="width: 200px"
            @search="handleSearch"
          />
        </div>
      </div>
      <a-table
        tableLayout="auto"
        :rowSelection="rowSelection"
        :rowKey="record => record.meu_id"
        :columns="columns"
        :loading="loading"
        :dataSource="menuFooter"
        class="utils__scrollTable"
        :scroll="{ x: '100%' }"
      >
        <router-link
          slot="meu_id"
          slot-scope="value"
          class="utils__link--underlined"
          :to="'/menu/footer/detail/' + value"
        >#<span v-if="searchText">
            <template
              v-for="(fragment, i) in value.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{value}}</template>
        </router-link>
        <template slot="customRender" slot-scope="text">
          <a-alert v-if="text === undefined" message="není nastaveno" type="warning" showIcon />
          <span v-else-if="searchText">
            <template
              v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{text}}</template>
        </template>
        <span
          slot="active"
          slot-scope="value"
          :class="[value === undefined ? '' : value ? 'font-size-12 badge badge-success' : 'font-size-12 badge badge-danger']"
        >
          <a-alert v-if="value === undefined" message="není nastaveno" type="warning" showIcon />
          <span v-else-if="searchText">
            <template
              v-for="(fragment, i) in (value ? 'ano' : 'ne').toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{value ? 'ano' : 'ne'}}</template>
        </span>
        <span slot="footerMenuTitle" slot-scope="text">
          <a-tag :color="text === 'není nastaveno' ? 'orange' : 'blue'">
            <span v-if="searchText">
              <template
                v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                >{{fragment}}</mark>
                <template v-else>{{fragment}}</template>
              </template>
            </span>
            <template v-else>{{text}}</template>
          </a-tag>
        </span>
        <span slot="action" slot-scope="record">
          <router-link :to="'/menu/footer/detail/'+record.meu_id">
            <a-button icon="search" size="small" class="mr-1">Zobrazit</a-button>
          </router-link>
          <a-popconfirm @confirm="confirmDelete([record.meu_id])" okText="Potvrdit" cancelText="Zrušit">
            <template slot="title">
                <p>Opravdu si přejete odstranit položku: #{{ record.meu_id }} ?</p>
            </template>
            <a-button icon="delete" size="small">Odstranit</a-button>
          </a-popconfirm>
        </span>
        <template slot="footer">
          {{ this.$store.getters['menuFooter/itemLength'](this.searchText) }} položek
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import LanguageTab from '@/components/MyComponents/LanguageTab'
import ActionTools from '@/components/MyComponents/ActionTools'

export default {
  components: { ActionTools, LanguageTab },
  data: function () {
    return {
      searchText: '',
      loading: false,
      selectedRowKeys: [],
      routerOptions: [
        {
          icon: 'plus-circle',
          to: '/menu/footer/add',
          title: 'Přidat',
        },
        {
          icon: 'sort-ascending',
          theme: 'outlined',
          to: '/menu/footer/sort',
          title: 'Seřadit',
        },
      ],
      columns: [
        {
          title: 'ID',
          dataIndex: 'meu_id',
          scopedSlots: {
            customRender: 'meu_id',
          },
          sorter: (a, b) => a.meu_id - b.meu_id,
        },
        {
          title: 'Nadpis',
          dataIndex: 'menu_languages[0].pivot.mle_menu_title',
          scopedSlots: {
            customRender: 'customRender',
          },
          sorter: (a, b) => a.menu_languages[0].pivot.mle_menu_title.localeCompare(b.menu_languages[0].pivot.mle_menu_title),
        },
        {
          title: 'Kategorie',
          dataIndex: 'footer_menu_title',
          scopedSlots: {
            customRender: 'footerMenuTitle',
          },
          sorter: (a, b) => a.footer_menu_title.localeCompare(b.footer_menu_title),
        },
        {
          title: 'E-shop',
          dataIndex: 'meu_is_ecommerce',
          scopedSlots: {
            customRender: 'active',
          },
          sorter: (a, b) => a.meu_is_ecommerce - b.meu_is_ecommerce,
        },
        {
          title: 'Blog',
          dataIndex: 'meu_is_blog',
          scopedSlots: {
            customRender: 'active',
          },
          sorter: (a, b) => a.meu_is_blog - b.meu_is_blog,
        },
        {
          title: 'Článek',
          dataIndex: 'meu_is_article',
          scopedSlots: {
            customRender: 'active',
          },
          sorter: (a, b) => a.meu_is_article - b.meu_is_article,
        },
        {
          title: 'V hlavním menu',
          dataIndex: 'meu_in_heading',
          scopedSlots: {
            customRender: 'active',
          },
          sorter: (a, b) => a.meu_in_heading - b.meu_in_heading,
        },
        {
          title: 'Zveřejněno',
          dataIndex: 'menu_languages[0].pivot.mle_is_active',
          scopedSlots: {
            customRender: 'active',
          },
          sorter: (a, b) => a.menu_languages[0].pivot.mle_is_active - b.menu_languages[0].pivot.mle_is_active,
        },
        {
          title: 'Akce',
          key: 'action',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
    }
  },
  computed: {
    menuFooter: function () {
      return this.$store.getters['menuFooter/currentLanguage'](this.searchText)
    },
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        selections: [{
          key: 'all-data',
          text: 'Vybrat všechny položky',
          onSelect: () => {
            this.selectedRowKeys = this.selectedRowKeys.length === 0 ? this.menuFooter.map(item => item.meu_id) : []
          },
        }],
        onSelection: this.onSelection,
      }
    },
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleRefresh() {
      this.loading = true
      this.$store.dispatch('menuFooter/getList')
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    confirmDelete(menuFooter) {
      this.loading = true
      this.$store.dispatch('menuFooter/delete', menuFooter)
        .then(() => {
          if (this.selectedRowKeys.length > 0) {
            this.selectedRowKeys = menuFooter.filter(x => !this.selectedRowKeys.includes(x))
              .concat(this.selectedRowKeys.filter(x => !menuFooter.includes(x)))
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    handleSearch(searchText) {
      this.searchText = searchText
    },
  },
  created() {
    if (this.menuFooter.length === 0 && this.$store.getters['language/active'] !== null) {
      this.handleRefresh()
    }
    this.$store.watch(
      (state, getters) => getters['language/active'], () => {
        if (this.$store.getters['language/active'] !== null && this.menuFooter.length === 0) {
          this.handleRefresh()
        }
      },
    )
  },
}
</script>

<style lang="scss" module scoped>

</style>
